import { useEffect, useState } from "react";
import { CircularProgress, Box, Typography, Fade } from "@mui/material";
import { useSelector } from "react-redux";

import Logo from "src/assets/images/default/FairmintLogo.svg";
import { getLogoFilter } from "src/theme/utils";

function GradientCircularProgress() {
  return (
    <>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#e01cd5" />
            <stop offset="100%" stopColor="#1CB5E0" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress sx={{ "svg circle": { stroke: "url(#my_gradient)" } }} />
    </>
  );
}

export default function FairmintLogoLoadingSpinner() {
  const themeMode = useSelector(state => state.global.themeMode);
  const quotes = [
    "Cap tables: Because spreadsheets needed a midlife crisis too.",
    "Making money from managing captables is like making money from opening a bank account.",
    "If your VCs still make you use Carta, their last win was Airbnb.",
    "Equity management smoother than your CEO's pitch to VCs.",
    "If your cap table is in Excel, your exit strategy might be in jeopardy.",
    "We make cap tables sexier than your pitch deck.",
    "Cap tables shouldn't be more complex than your product.",
    "If your cap table is giving you more headaches than your Series A negotiations, you're doing it wrong.",
    "If Carta is Web 2.0, we're Web3 - minus the crypto bros and questionable JPEGs.",
    "Making stock options less mysterious than your CTO's latest tech stack decision.",
  ];

  const [currentQuoteIndex, setCurrentQuoteIndex] = useState(Math.floor(Math.random() * quotes.length));
  const [showQuote, setShowQuote] = useState(false);

  useEffect(() => {
    const quoteTimer = setInterval(() => {
      setShowQuote(false);
      setTimeout(() => {
        setCurrentQuoteIndex(prevIndex => (prevIndex + 1) % quotes.length);
        setShowQuote(true);
      }, 1000); // Wait for fade out before changing quote
    }, 5000); // Change quote every 5 seconds

    // Show the first quote after a short delay
    const initialTimer = setTimeout(() => {
      setShowQuote(true);
    }, 500);

    return () => {
      clearInterval(quoteTimer);
      clearTimeout(initialTimer);
    };
  }, [quotes.length]);

  return (
    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <img
          src={Logo}
          alt="Fairmint logo"
          style={{
            height       : "3rem",
            marginBottom : "1rem",
            filter       : getLogoFilter(themeMode)
          }}
        />
        <GradientCircularProgress />
        <Box mt={3} height="3rem"> {/* Fixed height to prevent layout shift */}
          <Fade in={showQuote} timeout={1000}>
            <Typography fontSize="9px" sx={{ width: "250px", wordWrap: "break-word", textAlign: "center" }}>
              {quotes[currentQuoteIndex]}
            </Typography>
          </Fade>
        </Box>
      </Box>
    </Box>
  );
}

export function CustomLogoLoadingSpinner() {
  const themeMode = useSelector(state => state.global.themeMode);
  const christmasQuotes = [
    "Making your cap table merry & bright ❄️",
    "Wrapping up equity solutions 🎁",
    "Delivering clarity, all year round 🎄",
    "Your trusted equity partner ✨",
    "Spreading equity cheer 🌟",
    "Making equity management magical ⭐",
    "Where equity dreams come true 🎄",
    "Simplifying equity, one share at a time ❄️",
    "Building better equity solutions 🎁",
    "Excellence in every detail ✨",
  ];

  const [currentQuoteIndex, setCurrentQuoteIndex] = useState(Math.floor(Math.random() * christmasQuotes.length));
  const [showQuote, setShowQuote] = useState(false);

  useEffect(() => {
    const quoteTimer = setInterval(() => {
      setShowQuote(false);
      setTimeout(() => {
        setCurrentQuoteIndex(prevIndex => (prevIndex + 1) % christmasQuotes.length);
        setShowQuote(true);
      }, 1000);
    }, 5000);

    const initialTimer = setTimeout(() => {
      setShowQuote(true);
    }, 500);

    return () => {
      clearInterval(quoteTimer);
      clearTimeout(initialTimer);
    };
  }, [christmasQuotes.length]);

  const snowflakes = ["❄️", "❅", "❆", "⛄"]; // Snow and snowman emojis

  // Helper function to generate random number between min and max
  const random = (min, max) => Math.random() * (max - min) + min;

  return (
    <Box
      sx={{
        display        : "flex",
        justifyContent : "center",
        alignItems     : "center",
        height         : "100vh",
        background     : themeMode === "dark" ? "#1A1A1A" : "#FFFFFF",
        position       : "relative",
        overflow       : "hidden"
      }}
    >
      {/* Snow Animation - Only in Dark Mode */}
      <Box
        sx={{
          position : "absolute",
          inset    : 0,
          zIndex   : 1
        }}
      >
        {[...Array(30)].map((_, index) => (
          <Box
            // eslint-disable-next-line react/no-array-index-key
            key={`snow-${index}`}
            component="span"
            sx={{
              position              : "absolute",
              top                   : -20,
              left                  : `${random(0, 100)}%`,
              fontSize              : `${random(10, 16)}px`,
              color                 : "rgba(255, 255, 255, 0.7)",
              animation             : `snowfall ${random(10, 30)}s ease-in-out infinite`,
              animationDelay        : `-${random(0, 30)}s`,
              opacity               : themeMode === "dark" ? 0.7 : 0,
              "@keyframes snowfall" : {
                "0%": {
                  transform : `translate(0, -20px) rotate(0deg)`,
                  opacity   : 0.7
                },
                "25%": {
                  transform: `translate(${random(-100, 100)}px, 25vh) rotate(${random(180, 360)}deg)`,
                },
                "50%": {
                  transform : `translate(${random(-150, 150)}px, 50vh) rotate(${random(360, 720)}deg)`,
                  opacity   : 0.9
                },
                "75%": {
                  transform: `translate(${random(-100, 100)}px, 75vh) rotate(${random(720, 1080)}deg)`,
                },
                "100%": {
                  transform : `translate(${random(-50, 50)}px, 100vh) rotate(${random(1080, 1440)}deg)`,
                  opacity   : 0.7
                }
              },
              transition         : "opacity 0.5s ease-in-out",
              willChange         : "transform", // Optimize animation performance
              backfaceVisibility : "hidden" // Prevent flickering
            }}
          >
            {snowflakes[Math.floor(random(0, snowflakes.length))]}
          </Box>
        ))}
      </Box>

      {/* Main content */}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{
          padding      : "2rem",
          borderRadius : "16px",
          position     : "relative",
          zIndex       : 2
        }}
      >
        <Box sx={{ position: "relative" }}>
          <img
            src={Logo}
            alt="Fairmint logo"
            style={{
              height : "2.5rem",
              filter : getLogoFilter(themeMode)
            }}
          />
        </Box>

        <Box mt={2} display="flex" gap={2}>
          {["snow1", "snow2", "snow3"].map((id, i) => (
            <Box
              key={id}
              component="span"
              sx={{
                animation          : "float 3s ease-in-out infinite",
                animationDelay     : `${i * 0.5}s`,
                fontSize           : "2rem",
                "@keyframes float" : {
                  "0%, 100%": {
                    transform: "translateY(0)"
                  },
                  "50%": {
                    transform: "translateY(-15px)"
                  }
                }
              }}
            >
              🎄
            </Box>
          ))}
        </Box>

        <Box mt={3} height="3rem">
          <Fade in={showQuote} timeout={1000}>
            <Typography
              fontSize="10px"
              sx={{
                width      : "300px",
                wordWrap   : "break-word",
                textAlign  : "center",
                color      : themeMode === "dark" ? "#4CAF50" : "#2E7D32",
                fontWeight : 500,
                lineHeight : 1.6,
                transition : "all 0.3s ease-in-out",
                "&:hover"  : {
                  color     : themeMode === "dark" ? "#66BB6A" : "#1B5E20",
                  transform : "scale(1.02)"
                }
              }}
            >
              {christmasQuotes[currentQuoteIndex]}
            </Typography>
          </Fade>
        </Box>
      </Box>
    </Box>
  );
}
