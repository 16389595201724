import get from "lodash/get";
import { numberWithCommas } from "./number";

export const getTokenSymbol = (portalItem) => {
  if (get(portalItem, "attributes.token_symbol")) {
    return `$${get(portalItem, "attributes.token_symbol")}`;
  }
  return "<token symbol>";
};

export const formatDealTermsValue = (type, value) => {
  if (type === "date") {
    return value;
  }
  if (type === "number") {
    return numberWithCommas(value);
  }
  if (type === "dollar_value") {
    return `$${numberWithCommas(value)}`;
  }
  if (type === "percentage") {
    return `${value}%`;
  }
  return value;
};
