import { Dialog, DialogContent, DialogTitle, SvgIcon, Typography } from "@mui/material";
import PropTypes from "prop-types";
import InfoIcon from "@mui/icons-material/Info";
import useGlobalDialog from "src/hooks/use-globaldialog";

const CompleteDNSDialog = ({ onClose }) => {
  const {
    data: {
      props: { portalItem },
    },
  } = useGlobalDialog();
  console.log("portalItem", portalItem);
  const { id: portalId } = portalItem;
  return (
    <Dialog
      open
      onClose={onClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            maxWidth: "450px",
          },
        },
      }}
    >
      <DialogTitle
        component="div"
        sx={{ pt: 3, pb: 1, display: "flex", alignItems: "center" }}
      >
        <SvgIcon color="action" sx={{ mr: 1 }}>
          <InfoIcon />
        </SvgIcon>
        <Typography variant="h5">DNS Setup Incomplete</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" color="text.secondary" sx={{ pt: 1 }}>
          To preview the legal agreement, please complete the setup of your custom domain. Click <a href={`/p/${portalId}/settings?tab=dns`}>here</a> to get started!
        </Typography>
      </DialogContent>
    </Dialog>
  );
};
CompleteDNSDialog.propTypes = {
  onClose: PropTypes.func,
};

export default CompleteDNSDialog;
