import { useEffect, useMemo } from "react";
import { Navigate, useParams } from "react-router-dom";
import { usePrivy } from "@privy-io/react-auth";
import PropTypes from "prop-types";

import { ROUTE_PATH } from "src/constants";
import { usePathname } from "src/hooks";
import { useGetAdminUserInfo } from "src/services/getAdminUserInfo";
import Messenger from "src/utils/messenger";

const Layout = ({ children }) => {
  const { user } = usePrivy();
  const pathname = usePathname();
  const { portalId } = useParams();

  const { data: adminUserInfo } = useGetAdminUserInfo(user.id);
  const userItem = useMemo(() => adminUserInfo?.userItem, [adminUserInfo]);

  useEffect(() => {
    if (userItem) {
      Messenger.loadMessenger(userItem);
    }
  }, [userItem]);

  if (!userItem) return null;

  if (!portalId && pathname !== ROUTE_PATH.PORTALS) {
    return <Navigate to={ROUTE_PATH.PORTALS} />;
  }

  return <>{children}</>;
};

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
