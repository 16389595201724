export const PORTAL_STATUS = {
  NEW   : "new",
  SETUP : "setup",
};

export const ROUTE_PATH = {
  ROOT           : "/",
  LOGIN          : "/auth/login",
  SIGNUP         : "/auth/signup",
  PORTALS        : "/portals",
  DASHBOARD      : "dashboard",
  ONBOARDING     : "onboarding",
  WELCOME        : "welcome",
  WELCOME_INVITE : "welcome-invite",
  HOME           : "home",
  STAKEHOLDERS   : "stakeholders",
  SETTINGS       : "settings",
  // CAPTABLE       : "captable/overview",
  CAPTABLE       : {
    INDEX       : "captable",
    SUMMARY     : "captable/summary",
    STAKEHOLDER : "captable/stakeholder",
    ADMIN       : "captable/admin",
  },
  SERIES         : "series",
  WARRANT        : "warrant",
  GRANT          : "grant",
  SERIES_DEAL    : "series-deal",
  SERIES_PREVIEW : "series-preview",
  SHARES         : "shares",
  DATA_ROOM      : "data-room",
  FUNDRAISING    : {
    INVESTMENTS: "fundraising/investments",
  },
  GRANTS: {
    INDEX         : "grants",
    EQUITY_GRANTS : "grants/equity-grants",
    POOLS         : "grants/pools",
    SERIES        : "grants/series",
  },
  TAX: {
    INDEX             : "tax",
    STATE_FEES        : "tax/state-fees",
    FILINGS           : "tax/filings",
    RISK_FACTORS      : "tax/risk-factors",
    VALUTATION_REPORT : "tax/409a-report",
  },
  COMPANY: {
    // These are old routes, but we're keeping them around for now
    PROFILE  : "company/profile",
    SETTINGS : "company/settings",
  },
};

export const PROMPT_LOCATIONS = {
  SYSTEM_PROMPT: "SYSTEM_PROMPT",
};

export const ROLE_IN_PORTAL = {
  OWNER : "owner",
  ADMIN : "admin",
};

export const UPDATE_CONTENT_ACTION = {
  RISK_FACTORS: {
    UPDATE_HEADER   : "riskFactors_updateHeader",
    ADD_CATEGORY    : "riskFactors_addCategory",
    UPDATE_CATEGORY : "riskFactors_updateCategory",
    DELETE_CATEGORY : "riskFactors_deleteCategory",
  },
  ONBOARD_PORTAL         : "onboardPortal",
  UPDATE_PORTAL_SETTINGS : "updatePortalSettings",
};

export const APP_STAGE = {
  DEV        : "dev",
  STAGE      : "stage",
  PRODUCTION : "production",
};

export const TECH_SUPPORT_CODE = "TECH_SUPPORT_CODE";

export const CAFE_CONSTANTS = {
  MAX_UINT:
    "115792089237316195423570985008687907853269984665640564039457584007913129639935",
  MAX_GAS      : 6700000,
  ZERO_ADDRESS : "0x0000000000000000000000000000000000000000",
  STATE        : {
    INIT   : 0,
    RUN    : 1,
    CLOSE  : 2,
    CANCEL : 3,
  },
  STATES           : ["INIT", "RUN", "CLOSE", "CANCEL"],
  BASIS_POINTS_DEN : 10000,
};

export const COMPANY_TYPE = {
  LLC    : "Limited Liability Company",
  LP     : "Limited Partnership",
  C_COPR : "C Corporation",
  S_COPR : "S Corporation",
  GP     : "General Partnership",
  FE     : "Foreign Entity",
  TRUST  : "Trust",
  FUND   : "Investment company or fund",
};

export const AML_ACTION = {
  ACCEPT : "accept",
  ERROR  : "error",
  REJECT : "reject",
  SKIP   : "skip",
};

export const KYC_ACTION = {
  ACCEPT : "accept",
  REJECT : "reject",
  REVIEW : "review",
};

export const AML_MATCH_STATUS = {
  NO_MATCH              : "no_match",
  FALSE_POSITIVE        : "false_positive",
  POTENTIAL_MATCH       : "potential_match",
  TRUE_POSITIVE         : "true_positive",
  UNKNOWN               : "unknown",
  TRUE_POSITIVE_APPROVE : "true_positive_approve",
  TRUE_POSITIVE_REJECT  : "true_postive_reject",
};

export const COMPANY_STATUS_ACTION = {
  ACCEPT : "accept",
  REJECT : "reject",
};

export const ACCREDITATION_STATUS = {
  NO      : "no",
  NO_BUT  : "no but",
  INCOME  : "income",
  ASSETS  : "assets",
  LICENSE : "license",
};

export const COMPANY_ACCREDITATION_STATUS = {
  NO       : "no",
  OWNERS   : "owners",
  ASSETS   : "assets",
  BUSINESS : "business",
  FUND     : "fund",
};

export const INVESTMENT_STATUS = {
  SIGN_NEEDED        : "SIGN_NEEDED",
  SIGNED             : "SIGNED",
  CANCELED           : "CANCELED",
  FUNDS_SENT         : "FUNDS_SENT",
  AWAITING_SIGNATURE : "AWAITING_SIGNATURE",
  FUNDS_RECEIVED     : "FUNDS_RECEIVED",
  ADMIN_REFUSED      : "ADMIN_REFUSED",
  TOKENS_ISSUED      : "TOKENS_ISSUED",
  COMMITTED          : "COMMITTED",
  IMPORTED           : "IMPORTED",
};

export const GRANT_STATUS = {
  PENDING_TAX       : "PENDING_TAX",
  PENDING_SIGNATURE : "PENDING_SIGNATURE",
  USER_DECLINED     : "USER_DECLINED",
  CLIFF_PERIOD      : "CLIFF_PERIOD",
  READY_TO_ISSUE    : "READY_TO_ISSUE",
  READY_TO_EXERCISE : "READY_TO_EXERCISE",
  ISSUED            : "ISSUED",
  CANCELED          : "CANCELED",
  // Grants for V2 customers status below.
  SIGN_NEEDED       : "SIGN_NEEDED",
  VESTING           : "VESTING",
  VESTED            : "VESTED",
  EXERCISED         : "EXERCISED",
};

export const FUNDING_TYPE = {
  BANK_TRANSFER     : "bank_transfer",
  USDC_TRANSFER     : "usdc_transfer",
  SOLANA_TRANSFER   : "solana_transfer",
  ETHEREUM_TRANSFER : "ethereum_transfer",
  OTHER             : "other",
};

export const TX_TYPE = {
  INVESTMENT_USDC_TRANSFER : "investment_usdc_transfer",
  WHITELIST_WALLET         : "whitelist_wallet",
  INVESTMENT_ISSUE_TOKENS  : "investment_issue_tokens",
};

export const TX_STATUS = {
  QUEUED    : "queued",
  PENDING   : "pending",
  CONFIRMED : "confirmed",
  STUCK     : "stuck",
  FAILED    : "failed",
  DROPPED   : "dropped",
};

export const EVENT_TYPE = {
  ADMIN_APPROVED_INVESTOR_AML : "admin_approved_investor_aml",
  ADMIN_REJECTED_INVESTOR_AML : "admin_rejected_investor_aml",
  ADMIN_APPROVED_INVESTOR_KYC : "admin_approved_investor_kyc",
  ADMIN_REJECTED_INVESTOR_KYC : "admin_rejected_investor_kyc",
  ADMIN_APPROVED_INVESTOR_KYB : "admin_approved_investor_kyb",
  ADMIN_REJECTED_INVESTOR_KYB : "admin_rejected_investor_kyb",
  GRANT_CANCELED              : "grant_canceled",
};

export const WEBSOCKET_EVENT = {
  ADMIN_TOKEN_ISSUE_SUCCESS : "admin_token_issue_success",
  ADMIN_TOKEN_ISSUE_FAILED  : "admin_token_issue_failed",
  ADMIN_TASK_UPDATED        : "admin_task_updated",
  FUNDS_RECEIVED            : "funds_received",
  TOKEN_ISSUED              : "token_issued",
  ACCOUNT_CREATED           : "account_created",
  INVESTMENT_CREATED        : "investment_created",
  GRANT_CREATED             : "grant_created",
  SERIES_CREATED            : "series_created",
  CAPTABLE_MINTED           : "captable_minted",
  GRANT_SERIES_PUBLISHED    : "grant_series_published"
};

export const MAX_UINT = "115792089237316195423570985008687907853269984665640564039457584007913129639935";

export const SERIES_STATUS = {
  SETUP           : "setup",
  READY           : "ready",
  LIVE            : "live",
  PAUSED          : "paused",
  CLOSED          : "closed",
  SOFT_COMMITMENT : "soft_commitment",
  IMPORTED        : "imported",
};

export const DNS_STATUS = {
  NO_DOMAIN       : "NO_DOMAIN",
  WAITING_RECORDS : "WAITING_RECORDS",
  ADDED_RECORDS   : "ADDED_RECORDS",
  VERIFIED        : "VERIFIED",
};

export const SERIES_FILING_STATUS = {
  FILED     : "filed",
  NOT_FILED : "not_filed",
  PENDING   : "pending",
};

export const SERIES_TYPE = {
  FUNDRAISING  : "fundraising",
  GRANT        : "grant",
  VERIFICATION : "verification",
  SHARES       : "shares",
  WARRANT      : "warrant",
  TOKEN        : "token", // for token warrants and plain tokens
};

export const DIALOG_NAME = {
  SERIES_TYPE               : "SERIES_TYPE",
  CREATE_SERIES             : "CREATE_SERIES",
  SERIES_PUBLISH_READY      : "SERIES_PUBLISH_READY",
  SERIES_PUBLISH_COMMITMENT : "SERIES_PUBLISH_COMMITMENT",
  CONFIRM_SERIES_PUBLISH    : "CONFIRM_SERIES_PUBLISH",
  SUCCESS_SERIES_PUBLISH    : "SUCCESS_SERIES_PUBLISH",
  IMPORT_CAP_TABLE          : "IMPORT_CAP_TABLE",
  SUCCESS_IMPORT_CAP_TABLE  : "SUCCESS_IMPORT_CAP_TABLE",
  UPGRADE_FAIRMINT_ONE      : "UPGRADE_FAIRMINT_ONE",
  BRANDING                  : "BRANDING",
  AI                        : "AI",
  UPDATE_PITCH_DECK         : "UPDATE_PITCH_DECK",
  GRANT_EQUITY              : "GRANT_EQUITY",
  GRANT_EQUITY_LINK_SUCCESS : "GRANT_EQUITY_LINK_SUCCESS",
  EXPLORE_CAPTABLE          : "EXPLORE_CAPTABLE",
  YEAR_IN_REVIEW            : "YEAR_IN_REVIEW",
  COMPLETE_DNS              : "COMPLETE_DNS",
};

export const FILING_STATUS = {
  FILED     : "filed",
  PENDING   : "pending",
  NOT_FILED : "not_filed",
};

export const STATE_FEES = {
  PAID     : "paid",
  NOT_PAID : "not_paid",
};

export const TASK_TYPE = {
  ISSUE_TOKENS       : "issue_tokens",
  CHECK_BANK         : "check_bank",
  REVIEW_KYB         : "review_kyb",
  REVIEW_KYC         : "review_kyc",
  REVIEW_AML         : "review_aml",
  ISSUE_GRANT_TOKENS : "issue_grant_tokens",
  SETTINGS           : "settings",
  CHECK_SOLANA       : "check_solana",
  CHECK_ETH          : "check_eth"
};

export const TASK_SUBTYPE = {
  COMPANY_INFO : "company_info",
  EMAILS       : "emails",
  SOCIAL       : "social",
  LEGAL        : "legal",
  BRANDING     : "branding",
  BANK_INFO    : "bank_info",
  DNS          : "dns",
};

export const CONTRACT_SECURITIES_TYPE = {
  CONVERTIBLE : "convertible",
  SHARE       : "share",
};

export const S3_ASSET_TYPE = {
  KYC                 : "kyc",
  ACCREDITATION_PROOF : "accreditation_proof",
  CUSTOM_LEGAL_DOC    : "custom_legal_doc",
  COMPANY_DOC         : "company_doc",
  CAP_TABLE           : "cap_table",
  SERIES_DOC          : "series_doc",
};

export const PORTAL_PLAN = {
  FREE: "free",
};

export const CONTRACT_SUBTYPE = {
  RSU             : "rsu",
  OPTIONS         : "options",
  GRANT           : "grant",
  ROLLUP          : "rollup",
  SOFT_COMMITMENT : "soft_commitment",
};

export const LEGACY_CONTRACT = {
  CAFE           : "cafe",
  RSAFE          : "rsafe",
  COMMUNITY_SAFE : "csafe"
};

export const SERIES_TABS = {
  STAKEHOLDERS    : "stakeholders",
  INVESTMENTS     : "investments",
  ACTIVITY        : "activity",
  INVITATION_LINK : "invitation_link",
  COMPLIANCE      : "compliance",
  TERMS           : "terms",
  GRANTS          : "grants"
};

export const LEGAL_CONTRACT_ID = {
  CUSTOM: ["ff1dff86-42bb-45ed-be88-03182271f48e", "87ea7e65-452d-43ac-9c2e-0d8d2d9b6e4d"],
};

export const INVITATION_LINK_TYPE = {
  PUBLIC: "public"
};

export const ACCEPTED_TYPES = [
  ".ocx",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

export const EXEMPTION = {
  PROHIBITED : "prohibited",
  REGS       : "regS",
  REGD506C   : "regD506C",
  REGD506B   : "regD506B",
};

export const LOWEST_MIN_INVESTMENT = 500;

export const formDFilingURL = "https://buy.stripe.com/5kAg2C7fn5er1xu006";

export const MINIMUM_RAISED_FOR_YEAR_IN_REVIEW = 50_000;
